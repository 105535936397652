import axios from 'axios'
import config from './config'
import Cookies from "js-cookie"
import {MergeDicts} from "@/assets/ShareJS/GeneralFunction";


const baseURL = process.env.NODE_ENV === 'development' ? config.baseURL.dev : config.baseURL.pro

class HttpRequst{
    constructor(baseURL) {            //JS类constructor就是其构造函数
        this.baseURL = baseURL
    }
    getInsideConfig(){
        const config = {
            baseURL:this.baseURL,
            headers:{},
        }
        return config
    }
    interceptors(instance){
        // 添加请求拦截器
        instance.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            // 对于使用Ajax向Django后台发送的请求，需要设置一个名为X-CSRFTOKEN的标头，并将其值设置为存储在浏览器中的名为csrftoken的cookie，这是Django为了防止跨站攻击做出的要求。
            config.headers['X-CSRFToken'] = Cookies.get('csrftoken')


            // 如果sessionStorage中token不为空，那么每次请求前都要在头部加上token
            if(sessionStorage.getItem("token")){
                config.headers['TOKEN'] = sessionStorage.getItem("token")
            }
            return config;
          }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
          });

        // 添加响应拦截器
        instance.interceptors.response.use(function (response) {
            // 对响应数据做点什么
            return response;
          }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
          });
    }
    request(options){
        const instance = axios.create()
        //options = {...this.getInsideConfig(),...options}          //...扩展运算符
        options = MergeDicts(this.getInsideConfig(),options)
        this.interceptors(instance)
        console.log(options)
        return instance(options)
    }
}

export default new HttpRequst(baseURL)
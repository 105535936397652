import it from "element-ui/src/locale/lang/it";

function uuid2(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}



//合并两个字典，如果两个字典中有相同的key，且两个key的value同为数组或者字典时，将两个相同的key对应value的数组或者字典也进行合并
function MergeDicts(object1,object2){
    let dict = {}
    for (let item1 in {...object1}){
        //hasOwnProperty()函数用于指示一个对象自身(不包括原型链)是否具有指定名称的属性，因为 for in 循环总是遍历整个原型链上所有对象属性，当你在扩展本地原型后，for in会把你扩展的属性也给遍历出来
        //在 for in 循环时，建议增加 hasOwnProperty 进行判断，可以有效避免扩展本地原型而引起的错误，同时在多重循环中过滤掉不符合条件的对象，减少遍历次数。
        if(object1.hasOwnProperty(item1)){
            for (let item2 in {...object2}){
                if (object2.hasOwnProperty(item2)){
                    //判断是否有相同的key且相应的value都为字典
                    if (item1 === item2 && Object.prototype.toString.call(object1[item1]) === '[object Object]' && Object.prototype.toString.call(object2[item2]) === '[object Object]'){
                        dict[item1] = {...object1[item1],...object2[item2]}
                    //判断是否有相同的key且相应的value都为数组
                    }else if(item1 === item2 && Object.prototype.toString.call(object1[item1]) === '[object Array]' && Object.prototype.toString.call(object2[item2]) === '[object Array]'){
                        object1[item1].push.apply(object1[item1],object2[item2])
                        dict[item1] = object1[item1]
                    }
                }
            }
        }
    }
    return {...object1,...object2,...dict}
}




function GetCurrentTime(){
    //获取当前时间并打印
    let yy = new Date().getFullYear()
    let mm = new Date().getMonth()+1
    let dd = new Date().getDate()
    let hh = new Date().getHours()
    let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes()
    let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds()
    return yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss
}




export {uuid2,MergeDicts,GetCurrentTime}
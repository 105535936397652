<template>
    <header>
        <div class="header-logo">
            <span>
                <img :src="LogoImg">
            </span>
        </div>
        <div class="header-user">
            <el-dropdown trigger="click" size="mini">
                <span>
                    <img :src="UserImg">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>个人中心</el-dropdown-item>
                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </header>
</template>

<script>
    export default {
        name: "CommonHeader",
        data(){
            return{
                LogoImg:require("../assets/logo.png"),
                UserImg:require("../assets/user.png")
            }
        },
        methods:{
            logout(){
                this.$store.commit('clearToken')
                this.$store.commit('clearMenu')
                sessionStorage.removeItem('uuid')
                this.$router.push({name:'login'})
            }
        }
    }
</script>

<style scoped>
    header{
        display: flex;
        height: 100%;
        justify-content: space-between;/* 均匀排列每个元素,首个元素放置于起点，末尾元素放置于终点 */
    }
    .header-logo{
        display: flex;
        align-items:center;
    }
    .header-logo img{
        width: 50px;
        height: 50px;
    }
    .header-user{
        display: flex;
        align-items:center;
    }
    .header-user img{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

</style>
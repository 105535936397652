<template>
    <div>User页面</div>
</template>

<script>
    export default {
        name: "user",
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

</style>
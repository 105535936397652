<template>
    <div style="height: 100%; width: 100%">
        <div class="functionInfo">
            <el-collapse accordion @change="handleChange">
                <el-collapse-item title="信息采集文件分割功能" name="1" >
                    <div>将你在CLI界面以日志形式记录下来的信息采集文件，按命令截取你想要的部分，不同命令生成不同的文件；</div>
                    <div>请提前下载好Excel模板并准确填写相关信息，上传的文件名与Excel模板中的文件名不匹配将无法生成结果；</div>
                    <div>注意：可支持同时上传多个配置文件，但单个文件大小不能超过10M，总共不能超过50M，不然可能上传失败。</div>
                </el-collapse-item>
                <el-collapse-item title="" name="2">
                    <div></div>
                    <div></div>
                </el-collapse-item>
                <el-collapse-item title="" name="3">
                    <div></div>
                    <div></div>
                </el-collapse-item>
                <el-collapse-item title="" name="4">
                    <div></div>
                    <div></div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="functionView">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FunctionInfo",
        data(){
            return{

            }
        },
        methods:{
            handleChange(val){
                //val为当前激活的折叠面板的name值
                //console.log(val)
            },
        }
    }
</script>

<style scoped>
    .functionInfo{
        padding-top: 10px;
        height: 400px;
        width: 100%;
    }
    .functionView{
        padding-top: 20px;
        height: 400px;
        width: 100%;
    }


</style>
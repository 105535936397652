import Vue from 'vue'
import App from './App.vue'
import router from "../src/router/router";
import store from "../src/store/store";
import {Button,Container,Aside,Header,Main,Dropdown,DropdownItem,DropdownMenu,Form,FormItem,Input,Collapse,CollapseItem,
  Menu,MenuItem,MenuItemGroup,Submenu,Row,Col,Upload,Dialog,Timeline,TimelineItem,Message,Card,Divider,Progress} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.css'
import axios from "@/api/axios";




Vue.use(Button)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Progress)
Vue.prototype.$message = Message









Vue.config.productionTip = false



// 导航守卫逻辑
// 这里存在回调函数异步执行的问题，router.beforeEach和axios.request的回调函数都是异步执行的，但是axios去请求后端的返回数据需要时间。
// 所以还未等到后端返回值，后面的代码就会被执行，导致变量result的值未能更改。
// 此时要用到ES7中的异步特性async/await，async用于申明一个函数是异步的，await等待异步请求完成，await只能在async方法中使用。
router.beforeEach(async function(to,from,next){
    store.commit('getToken')
    var authed = false
    var res
    await axios.request({url:'/route_auth',method:'get',data:''}).then(function (response) {
        if(response.data.ret === 0){
            authed = true
        }
        res = response
    })
    // 这里权限的逻辑就是如果未认证并且下一跳不是跳转到login页面时，强制跳转到login页面
    if(!authed && to.name!=='login'){
        // 如果是首次访问去往home页面那么不提示直接跳转到login页面，如果未认证并且不是访问首页则判定为有攻击嫌疑，弹出警告
        // if(to.name !== 'home'){
        //     alert(res.data.msg)
        // }
        next({name:'login'})
    }else
        next()
})





new Vue({
  router:router,
  store:store,
  render: h => h(App),
}).$mount('#app')

<template>
    <div>
        <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="login-container">
            <h3 class="login_title">系统登录</h3>
            <el-form-item
                    label="用户名"
                    label-width="80px"
                    prop="username">
                <el-input type="input" v-model="form.username" autocomplete="off" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item
                    label="密码"
                    label-width="80px"
                    prop="password">
                <el-input type="password" v-model="form.password" autocomplete="off" placeholder="请输入密码" @keyup.enter.native="login"></el-input>
            </el-form-item>
            <el-form-item class="login_submit">
                <el-button type="primary" @click="login" class="login_submit">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import axios from "@/api/axios";
    import {uuid2} from "@/assets/ShareJS/GeneralFunction";

    export default {
        name: "login",
        data(){
            return{
                form:{
                    action:'signin',
                    username: '',
                    password: '',
                },
                rules:{
                    username:[
                        {
                            required:true,
                            message:"请输入用户名",
                            trigger:"blur"
                        },
                        {
                            min:3,
                            message: "用户名长度不能小于3位",
                            trigger:"blur"
                        }
                    ],
                    password:[
                        {
                            required:true,
                            message:"请输入密码",
                            trigger:"blur"
                        },
                        {
                            min:3,
                            message: "密码长度不能小于3位",
                            trigger:"blur"
                        }
                    ]
                }
            }
        },
        methods:{
            login(){
                //赋值操作只是将引用类型对象保存在栈中的地址赋给另一个新创建的对象，它们共享栈中地址所指向的堆中的内存，为浅拷贝。
                //function(response)函数中的this是指向调用这个函数的mounted()，而不是外层的Vue组件，可以使用that=this，或者使用箭头函数，将this重定向到VC组件上。
                const that = this
                if (this.form.username === '' || this.form.password === '') {
                    alert('账号或密码不能为空')
                }else{
                    axios.request({url:'/sign',method:'post',data:this.form}).then(
                        function (response) {
                            //ret为 0，说明后端正常响应
                            if(response.data.ret===0){
                                //is_superuser为 true，说明为管理员权限，菜单栏添加User界面
                                if(response.data.is_superuser){
                                    that.$store.commit('addUserMenu')
                                    that.$store.commit('getMenu')
                                    //如果没有管理员权限，而且此时Menu中又存在UserMenu，则删除UserMenu
                                }else{
                                    that.$store.commit('initUserMenu')
                                    that.$store.commit('getMenu')
                                }
                                //为本次登录者生成唯一UUID存入本地SessionStorage
                                sessionStorage.setItem('uuid',uuid2(16))
                                //将服务器返回的token存入本地SessionStorage
                                that.$store.commit('setToken',response.data.token)
                                that.$router.push({name:'home'})
                            }else{
                                alert(response.data.msg);
                            }
                        }
                    )
                }
            }
        },
        mounted(){
            axios.request({url:'/get_csrf_token',method:'get'})
        },
    }
</script>

<style scoped>
    div{
        padding: 1px;
        /*对于两个相邻父子元素，如果只对子元素设置了margin-top的值，你会发现页面出现了竖向滚动条，
        因为两个相邻父子元素没有border或者padding进行分割，那么会发生外边距合并的现象，并且合并后的外边距以相邻元素中较大的外边距为最终结果*/
    }
    .login-container{
        border-radius: 15px;
        background-clip: padding-box;
        margin:180px auto;
        width: 350px;
        padding: 35px 35px 15px 35px;
        background-color: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
    }
    .login_title{
        margin: 0px auto 40px auto;
        text-align: center;
        color: #505458;
    }
    .login_submit{
        margin: 10px auto 0px auto;
    }
</style>
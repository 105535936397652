<template>
    <div>home页面</div>
</template>

<script>
    import axios from "@/api/axios";
    export default {
        name: "home",
        data(){
            return{

            }
        },
        //mounted() {
        //    axios.request({url:'/test',method:'get',data:''}).
        //}
    }
</script>

<style scoped>

</style>
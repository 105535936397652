<template>
    <el-container style="height:100%; width:1000px; margin: auto" @click.native="moveEvent">
        <el-header height="100px">
            <common-header></common-header>
        </el-header>
        <el-container>
            <el-aside width="210px">
                <common-aside></common-aside>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonAside from "@/components/CommonAside";
    export default {
        name: "frame",
        components:{CommonHeader,CommonAside},
        data(){
            return{}
        },
        methods:{
            moveEvent() {
                if (this.timmer) {
                    clearTimeout(this.timmer);  //停止计时
                }
                this.initTimer();
            },

            initTimer() {
                this.timmer = setTimeout(() => {
                    this.$store.commit('clearToken')
                    this.$store.commit('clearMenu')
                    this.$router.push({name:'login'});
                }, 1000 * 60 *30); //半个小时到期自动跳转登录页面
            },
        },
        mounted() {
            this.moveEvent();
        }

    }
</script>

<style scoped>
    .el-header{
        border-bottom-style:solid;
        border-width:1px;
        border-color: #cccccc;
        box-shadow: 0 6px 5px -4px #cac6c6;
    }
    .el-main{
        padding-top: 6px;
    }
    .el-aside{
        padding-top: 6px;
        border-right-style:solid;
        border-width:1px;
        border-color: #cccccc;
    }

</style>
<template>
    <el-menu :default-active="$route.path" :router="true" class="el-menu-vertical-demo" background-color="white" active-text-color="dodgerblue" @open="handleOpen" @close="handleClose" >
        <el-menu-item v-for="(item,index) in noChildren" :index="item.path" :key="item.label">
          <i :class="'el-icon-' + item.icon"></i>
          <span slot="title">{{item.label}}</span>
        </el-menu-item>
        <el-submenu v-for="(item,index) in hasChildren" :index="item.path" :key="item.label">
          <template slot="title">
            <i :class="'el-icon-' + item.icon"></i>
            <span>{{item.label}}</span>
          </template>
          <el-menu-item-group v-for="(subItem,subIndex) in item.children" :key="subItem.label">
            <el-menu-item :class="'el-icon-' + subItem.icon" :index="subItem.path">{{subItem.label}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
    </el-menu>
</template>

<script>
    export default {
        name: "CommonAside",
        data(){
            return{
                menu:JSON.parse(sessionStorage.getItem("menu"))
            }
        },
        methods: {
            handleOpen(key, keyPath) {
                //console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                //console.log(key, keyPath);
            },
            // 在这里不使用点击事件而是使用element ui导航菜单的vue-router模式（:router="true"），启用该模式会在激活导航时以index作为path进行路由跳转
            //clickMenu(item){
            //    //Vue.use(VueRouter)在Vue实例上注册了一个路由器组件VueRouter，并用其实例化了一个路由器router实例。
            //    //同时该router实例对象会以$router属性的形式挂载在Vue下，VueComponent继承了该属性（父子类继承关系在前端中叫做原型链），所以在组件可以用this.$router查看该router实例对象。
            //    //编程式路由导航
            //    //if判断解决Vue重复点击相同路由，控制台出现报错问题
            //    if(this.$route.path !== item.path){
            //        console.log("当前path："+this.$route.path+",目标path："+item.path)
            //        this.$router.push({
            //            name:item.name
            //        })
            //    }
            //}
      },
        computed:{
            noChildren(){
                return this.menu.filter(function (item) {
                          return !item.children
                })
            },
            hasChildren(){
                return this.menu.filter(function (item) {
                          return item.children
                })
            },
      }
    }
</script>

<style scoped>

</style>
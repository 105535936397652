<template>
    <div>
        <span>配置文件分割功能模块，按步骤操作</span>
        <el-divider><i class="el-icon-mouse"></i></el-divider>
        <el-timeline>
            <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :timestamp="activity.timestamp">
                {{activity.content}}
            </el-timeline-item>
        </el-timeline>
        <el-button type="primary" plain @click="downloadDemo" icon="el-icon-download">下载模板</el-button>
        <el-button type="primary" @click="dialogType = '上传excel文件' "><i class="el-icon-upload el-icon--right"></i> 上传excel文件</el-button>
        <el-button type="primary" @click="dialogType = '上传配置文件' "><i class="el-icon-upload el-icon--right"></i> 上传配置文件</el-button>
        <el-button type="primary" @click="downloadRes" icon="el-icon-download">下载结果</el-button>
        <el-dialog title="文件上传" width="450px" v-if="dialogType === '上传excel文件'" :visible="true" @close="closeDialog">
            <el-row>
                <el-upload ref="excel_upload"
                        class="upload-demo"
                        accept=".xls,.xlsx"
                        action="127.0.0.1"
                        drag
                        :auto-upload="false"
                        :multiple="false"
                        :file-list="fileList"
                        :before-upload="beforeUpload"
                        :http-request="uploadExcelHttpRequest"
                        :on-remove="fileRemove"
                        :on-change="fileChange">
                    <!-- action：上传的地址，可以不用过多关注 -->
                    <!-- auto-upload：是否自动上传，因这里是手动上传，所以设置为false -->
                    <!-- multiple：是否支持多选，此处设置为 false -->
                    <!-- file-list：上传的文件列表数组 -->
                    <!-- before-upload：上传文件之前的钩子，参数为上传的文件，可以在这里判断上传文件的类型，文件大小等 -->
                    <!-- http-request：自定义上传的方法，会覆盖默认的上传行为（即action="url"） -->
                    <!-- on-remove：上传文件移除时触发的方法 -->
                    <!-- on-change：上传文件状态（添加，上传成功或失败）改变时触发的方法 -->
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件，且不超过1M</div>
                </el-upload>
            </el-row>
            <el-row>
                <el-button type="primary" size="small" @click="closeDialog">关 闭</el-button>
                <el-button type="primary" :disabled="uploadDisabled" size="small" @click="submitUpload">上 传</el-button>
            </el-row>
        </el-dialog>
        <el-dialog title="文件上传" width="450px" v-if="dialogType === '上传配置文件'" :visible="true" @close="closeDialog">
            <el-row>
                <el-upload ref="config_upload"
                        class="upload-demo"
                        accept=".txt,.log"
                        action="127.0.0.1"
                        drag
                        :auto-upload="false"
                        :multiple="true"
                        :file-list="fileList"
                        :before-upload="beforeUpload"
                        :http-request="uploadTxtHttpRequest"
                        :on-remove="fileRemove"
                        :on-change="fileChange">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">只能上传txt/log文件，且单个文件不超过10M</div>
                </el-upload>
                <el-progress v-if="showProgress" :text-inside="true" :percentage="ulProgress" :stroke-width="15" status="success"></el-progress>
            </el-row>
            <el-row>
                <el-button type="primary" size="small" @click="closeDialog">关 闭</el-button>
                <el-button type="primary" :disabled="uploadDisabled" size="small" @click="submitUpload">上 传</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    import axios from "@/api/axios";
    import {GetCurrentTime} from "@/assets/ShareJS/GeneralFunction";

    export default {
        name: "SplitConfigFile",
        data(){
            return{
                dialogType: '',
                uploadDisabled: true,
                showProgress: false,
                ulProgress:0,
                fileList:[],
                activities: [{
                    content: '下载excel模板',
                    timestamp: '',
                    size: 'large',
                    type: 'primary',
                    icon: 'el-icon-more'
                }, {
                    content: '上传excel文件',
                    timestamp: '',
                    size: 'large',
                    color: '',
                    icon:'el-icon-check'
                }, {
                    content: '上传配置文件',
                    timestamp: '',
                    size: 'large',
                    color: '',
                    icon:'el-icon-check'
                }, {
                    content: '结果返回',
                    timestamp: '',
                    size: 'large',
                    color: '',
                    icon:'el-icon-check'
                }],
            }
        },
        methods: {
            downloadDemo(){
                //一定要设置服务器响应的数据类型为blob类型
                axios.request({url:'/download_SplitConfigTemplate',method:'get',responseType: 'blob'})
                    .then( response => {
                        if(response.status===200) {
                            //excel文件和配置文件上传完毕后在时间线显示相应状态
                            this.activities[0].timestamp = GetCurrentTime()
                            // response就是接口返回的文件流了,type指定为xlsx文件
                            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                            let objectUrl = URL.createObjectURL(blob)
                            let filename = response.headers['content-disposition'].split(';')[1].split('"')[1].split('.')[0]
                            //利用a标签做下载
                            const a = document.createElement('a')
                            a.download = filename  //后缀名一定要写对，不然会导致文件损坏，打不开
                            a.href = objectUrl
                            a.click()
                            a.remove()
                        }
                    })
            },
            downloadRes(){
                const uuid = sessionStorage.getItem('uuid')
                //一定要设置服务器响应的数据类型为blob类型
                axios.request({url:'/download_SplitConfigResult',method:'get',headers:{uuid},responseType: 'blob'})
                    .then( response => {
                        if(response.data.type==="application/json") {
                            //结果返回失败
                            this.$message({showClose: true, message: '无法生成结果，请检查上传信息是否无误！', type: 'error'})
                        }else {
                            //结果返回成功后在时间线显示相应状态
                            this.activities[3].color = '#0bbd87'
                            this.activities[3].timestamp = GetCurrentTime()
                            this.$message({showClose: true, message: '成功生成结果！', type: 'success'})
                            let blob = new Blob([response.data], { type: 'application/octet-stream' })
                            let objectUrl = URL.createObjectURL(blob)
                            let filename = response.headers['content-disposition'].split(';')[1].split('"')[1].split('"')[0]
                            //利用a标签做下载
                            const a = document.createElement('a')
                            a.download = filename  //后缀名一定要写对，不然会导致文件损坏，打不开
                            a.href = objectUrl
                            a.click()
                            a.remove()
                        }
                    })
            },
            // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传，该钩子函数缺省只有一个参数file，为上传的每一个file对象，上传了多少个文件就调用多少次该钩子函数
            beforeUpload(file) {
                let fileLimit = false
                // 单个文件大小限制为10M
                if(this.dialogType=== '上传excel文件'){
                    fileLimit = file.size / 1024 / 1024 < 1;
                    if(!fileLimit) {
                        this.$message({showClose: true, message: '单个上传文件大小不超过1M!', type: 'error'})
                    }
                }else if(this.dialogType=== '上传配置文件'){
                    this.fileList.forEach(file => {
                        fileLimit = file.size / 1024 / 1024 < 10
                        this.ulProgress = 0
                        if (!fileLimit) {
                            this.$message({showClose: true, message: '上传文件：《' + file.name + '》的大小不能超过10M!', type: 'error'})
                        }
                    })
                }
                return fileLimit
            },
            // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
            uploadExcelHttpRequest(param) {
                const formData = new FormData() //FormData对象，添加参数只能通过append('key', value)的形式添加
                formData.append('file', param.file) //添加文件对象
                const uuid = sessionStorage.getItem('uuid')
                axios.request({url:'/SplitFunction_upload_excel',method:'post',data:formData,headers:{uuid}})
                    .then( response => {
                        //未重新选择文件则禁用上传按钮
                        this.uploadDisabled = true
                        //上传成功执行后续相关操作
                        if(response.data.ret===0) {
                            param.onSuccess()  // 上传成功的文件显示绿色的对勾
                            //excel文件上传完毕后在时间线显示相应状态
                            this.activities[1].color = '#0bbd87'
                            this.activities[1].timestamp = GetCurrentTime()
                        }else {
                                this.$message({showClose: true, message: '文件上传失败', type: 'error'})
                                this.fileList = []
                                this.uploadDisabled = true //未重新选择文件则禁用上传按钮
                            }
                    })
                    .catch( err => {
                        this.uploadDisabled = true //未重新选择文件则禁用上传按钮
                        param.onError() //上传失败的文件会从文件列表中删除
                    })
            },
            uploadTxtHttpRequest(){
                //因为没有使能自动上传参数”auto-upload“，也没有在submitUpload()中手动触发up-load组件submit，所以文件上传前钩子函数beforeUpload不会自动调用，需要自己进行调用
                if(this.beforeUpload()){
                    const formData = new FormData()
                    this.fileList.forEach(file => formData.append('files', file.raw))
                    const uuid = sessionStorage.getItem('uuid')
                    let that = this
                    axios.request({
                        url:'/SplitFunction_upload_txt',
                        method:'post',
                        data:formData,
                        headers:{uuid},
                        onUploadProgress(progress){
                            that.ulProgress = Math.round(progress.loaded / progress.total * 100)
                        }
                        //onUploadProgress(progress => {this.ulProgress = Math.round(progress.loaded / progress.total * 100) + '%'})
                    }).then( response => {
                        //清除本次上传产生的fileList
                        this.fileList = []
                        //未重新选择文件则禁用上传按钮
                        this.uploadDisabled = true
                        //初始化上传进度相关参数
                        this.showProgress = false
                        this.ulProgress = 0
                        //上传成功执行后续相关操作
                        if(response.data.ret===0) {
                            //上传成功进行提示
                            this.$message({showClose: true, message: '文件上传成功', type: 'success'})
                            //配置文件上传完毕后在时间线显示相应状态
                            this.activities[2].color = '#0bbd87'
                            this.activities[2].timestamp = GetCurrentTime()
                        }else {
                            this.$message({showClose: true, message: '文件上传失败', type: 'error'})
                            this.fileList = []
                            this.uploadDisabled = true //未重新选择文件则禁用上传按钮
                        }
                    }).catch( err => {
                        this.$message({showClose: true, message: '文件上传失败', type: 'error'})
                        this.fileList = []
                        this.uploadDisabled = true //未重新选择文件则禁用上传按钮
                    })
                }
            },
            // 点击上传：手动上传到服务器，此时会触发组件的http-request
            submitUpload() {
                if(this.dialogType=== '上传excel文件'){
                    this.$refs.excel_upload.submit()
                }else if(this.dialogType=== '上传配置文件'){
                    // 自定义上传行为，将多个文件放在一个请求中，否则调用up-load默认的submit事件，组件会将多文件逐一请求上传，占用服务器性能
                    this.uploadTxtHttpRequest()
                    this.showProgress = true
                }
            },
            // 文件发生改变
            fileChange(file, fileList) {
                if(this.dialogType=== '上传excel文件'){
                    // 上传多个excel文件时，只保留并展示最后一个文件
                    if (fileList.length > 0) {
                        this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
                    }
                }else if(this.dialogType=== '上传配置文件'){
                    // 可以上传多个配置文件
                    this.fileList = fileList
                }
                this.uploadDisabled = false //未重新选择文件则禁用上传按钮
            },
            // 移除选择的文件
            fileRemove(file, fileList) {

            },
            // 取消
            closeDialog() {
                if(this.dialogType === '上传excel文件'){
                    this.$refs.excel_upload.clearFiles() //清除上传文件对象
                }else if(this.dialogType === '上传配置文件'){
                    this.$refs.config_upload.clearFiles() //清除上传文件对象
                }
                this.fileList = [] //清空选择的文件列表
                this.$emit('close', false)
                this.dialogType = ''
                this.showProgress = false
                this.ulProgress = 0
            },
        }
    }
</script>

<style scoped>
    .el-row {
        margin-bottom: 20px;
        text-align: center
    }
</style>
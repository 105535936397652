import Vue from 'vue'
import Vuex from 'vuex'
import tokendata from './TokenData'
import menudata from "@/store/MenuData";

Vue.use(Vuex)


export default new Vuex.Store({
    modules:{
        tokendata,
        menudata,
    }
})
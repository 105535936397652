import Vue from 'vue'
import VueRouter from 'vue-router'
import frame from "@/views/frame"
import user from "@/views/user/user"
import home from "@/views/home/home"
import login from "@/views/login/login"
import FunctionView from "@/views/function/FunctionView";
import SplitConfigFile from "@/views/function/SplitConfigFile";



Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name:'main',
        component:frame,
        //打开根页面时将其重定向到/home页面
        redirect:'/home',
        children:[
            {
                path:'/home',
                name:'home',
                component:home
            },
            {
                path:'/user',
                name:'user',
                component:user
            },
            {
                path:'/function',
                name:'function',
                component: FunctionView,
                children:[
                    {
                        path:'/function/SplitConfigFile',
                        name:'SplitConfigFile',
                        component:SplitConfigFile
                    },
                ]
            }
        ]
    },
    {
        path:'/login',
        name:'login',
        component:login,
    }
]

const  router = new VueRouter({
    mode:'history',
    routes
})

export default router
export default{
    state:{
        token:''
    },
    mutations:{
        setToken(state,val){
            state.token  = val
            sessionStorage.setItem('token',val)
        },
        clearToken(state){
            state.token = ''
            sessionStorage.removeItem("token")
        },
        getToken(state){
            state.token = state.token || sessionStorage.getItem("token")
        }
    }
}
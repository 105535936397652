export default{
    state:{
        menu:[
            {
                path:'/home',
                name:'home',
                label:'首页',
                icon:'s-home',
            },
            {
                path: '/function',
                name:'function',
                label:'功能',
                icon:'s-operation',
                children:[
                    {
                        path:'/function/SplitConfigFile',
                        name:'SplitConfigFile',
                        label:'文件分割',
                        icon:'paperclip',
                    },
                ]
            }
            ],
        hasusermenu:false,
    },
    mutations:{
        getMenu(state){
            sessionStorage.setItem('menu',JSON.stringify(state.menu))
        },


        addUserMenu(state){
            //先判断menu中有没有user，如果有就不再重复添加
            const hasusermenu = state.menu.some(function(item) {
                if (item.name === "user") {
                    return true;
                }
            })
            if(!hasusermenu){
                state.menu.splice(1,0,{
                    path:'/user',
                    name:'user',
                    label:'用户管理',
                    icon:'user',
                    }
                )
            }
        },


        initUserMenu(state){
            //先判断menu中有没有user，如果就删除user
            const hasusermenu = state.menu.some(function(item) {
                if (item.name === "user") {
                    return true;
                }
            })
            if(hasusermenu){
                state.menu.splice(1,1)
            }
        },


        clearMenu(state){
            sessionStorage.removeItem('menu')
        },
    }
}